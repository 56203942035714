.joinOurTeam{
    margin-top: 50px;
}
.see-Oportunities {
    position: relative;
    letter-spacing: 0.05em;
    line-height: 38px;
    color: white;
}

.see-Oportunities-wrapper {
    display: flex;
    padding: var(--padding-3xs);
    justify-content: center;
}

.see-Oportunities-btn {
    width: 35%;
    border-radius: var(--br-48xl);
    border: 1px solid var(--color-gray-300);
    padding: 0 40px;
    font-size: var(--font-size-6xl);
    background-color: black;
    font-family: var(--font-comfortaa);
}