.form-input-contact{
    border: white;
    border-bottom: ridge;
    width: 300px;
    height: 60px;
    padding: 10px 5px 0px 5px;
    margin-left: 50px;
    background: #FFFCF4;
    font-family: 'Comfortaa';
}

.Contact-leftside{
    margin: 0px 0px 55px 0px;
}

#contact-form {
    --bs-body-bg: transparent;
    --bs-border-width: 0;
    --bs-border-radius: 0;

    .form-floating {

        .form-control {
            border-bottom: 1px solid #000000;
            padding: 1.5rem 0 1rem 0;

            &:focus {
                box-shadow: none;
            }

            &:focus ~ label,
            &:not(:placeholder-shown) ~ label {
                transform: scale(0.85) translateY(-1.5rem) translateX(0.15rem);
                color: var(--color-black);
            }
        }

        > label {
            padding: 1.5rem 0;
            color: #D4D4D8;
        }
    }
}
