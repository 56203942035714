.careerPoster {
    display: flex;
    align-items: center;
    justify-content: space-evenly;

    background-color: #ece4ce;
    width: 100%;
    height: 850px;
    color: rgba(29, 28, 27, 0.5);
    font-family: var(--font-comfortaa);
}

.careerPoster>* {
    margin: 60px;

    @media screen and (max-width: 768px) {
        margin: 4rem 2rem;
    }
}

.careerPoster-left img {
    /*border-radius: 25px;*/
    /*width: 614px;*/
    /*height: 690px;*/
    /*object-fit: cover;*/
    background-color: black;
}

.careerPoster-plainText {

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 25px;
    text-align: justify;
    font-size: 1.25rem;
    font-family: var(--font-comfortaa);
}

.careerPoster-plainText > * {
    position: relative;
    line-height: 168.8%;
    display: flex;
    align-items: center;
    color: black;
}
