.home-poster {
    position: relative;
    text-align: center;
    color: var(--color-floralwhite);
    font-family: var(--font-soria);

    @media screen and (max-width: 768px) {
        //height: 600px;
    }
}

.home-banner-wrapper {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
}

.home-poster-img {
    //position: absolute;
    //top: 0;
    //left: calc(50% - 960px);
    //width: 1730px;
    //height: 100%;
    //object-fit: cover;
    width: 100%;
}

.home-poster-text {
    position: absolute;
    text-transform: uppercase;
    width: 100%;
    max-width: 65%;
    padding: 20px;
    line-height: 4rem;
    z-index: 1;

    @media screen and (max-width: 768px) {
        max-width: 75%;
        padding: 0;
    }
}

.home-poster-text > p {
    margin: 0;
    font-size: 3rem;
    text-shadow: 0 4px 16px rgba(255,255,255,0.4);

    @media screen and (max-width: 768px) {
        font-size: 2.5rem;
    }
}


.homePoster-about-us-btn {
    position: absolute;
    bottom: 20%;
    left: 0;
    right: 0;

    @media screen and (max-width: 768px) {
        //bottom: 0;
        bottom: 7.5%;
    }
}

.homePoster-about-us-wrapper {
    display: flex;
    flex-direction: row;
    padding: var(--padding-3xs);
    align-items: flex-start;
    justify-content: flex-start;
}

.btn {

    &.homePoster-about-us {
        position: relative;
        letter-spacing: 0.05em;
        line-height: 38px;
    }
}


