.teams {
    padding: 0 2rem;
}

.team-name {
    margin-top: 50px;
    font-size: 40px;
    font-family: var(--font-soria);
}

.profileCards {
    //display: grid;
    //align-items: center;
    //justify-content: center;
    //grid-template-columns: repeat(4, 1fr);
}

.profileCard {
    //margin: 50px 45px 0 0;
}

.profileCard-img {
    //width: 280px;
    //height: 350px;
    //filter: grayscale(100%);
    width: 100%;
    height: auto;
}

.profileCard-name {
    position: relative;
    font-weight: 800;
    font-size: 20px;
    margin: 15px 0;
    font-family: var(--font-futura);
}

.profileCard-name a {
    font-family: 'Neue Haas Grotesk Display Pro', sans-serif;
    color: var(--color-black);
}


.profileCard-post,
.profileCard-info {
    font-weight: 500;
    margin-bottom: 7px;
    color: var(--color-black);
    font-family: var(--font-comfortaa);
}

.profileCard-url {
    color: #0a66c2;
    font-size: x-large;
}
