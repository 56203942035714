.makeYourMark{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin: 200px;


    @media screen and (max-width: 768px) {
        margin: 4rem;
    }
}

.makeYourMark-btns{
    margin: 40px 0;
    width: 60%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

#careers {
    &.accordion {
        --bs-accordion-bg: transparent;
        --bs-accordion-active-bg: transparent;

        .accordion-header {
            button {
                font-size: 1.5rem;
                box-shadow: none;
            }
        }

        .accordion-body {
            font-size: 1.2rem;
            line-height: 2rem;
        }
    }
}
