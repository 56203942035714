.portfolio-header {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    font-size: 1.5rem;
    line-height: 30px;
    font-family: var(--font-comfortaa);
}

.portfolio-items {

    .portfolio-item {
        border-radius: 0;
        border-width: 0 0 1px 0;
        background: transparent;

        .card-title {
            a {
                font-family: var(--font-comfortaa);
                font-weight: 700;
                font-size: 2rem;
                //line-height: 3rem;

                &[href="#"] {
                    text-decoration: none;
                }
            }
        }

        .card-subtitle {
            font-size: 1.25rem;
            opacity: 50%;
        }

        .card-text {
            font-size: 1rem;
            text-align: justify;
        }
    }
}

.portfolioCard{
    width: 80%;
    margin: 0 150px;
    padding: 50px 0;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    box-sizing: border-box;
    border-bottom: 1px solid black;
}

.portfolioCard-status {
    font-family:var(--font-comfortaa);
    font-weight: 700;
    color: gray;

    span {
        color: var(--bs-body-color);
    }
}

.portfolioCard-img{
    border-radius: 50%;
    width: 150px;
    height: 150px;
}

.portfolioCard-data{
    width: 50%;
    margin: 0 130px;
}

.portfolioCard-title{
    font-size: 36px;
    font-weight: 700;
    line-height: 45px;
    font-family: var(--font-comfortaa);

    &[href="#"] {
        text-decoration: none;
    }
}
.portfolioCard-category{
    margin: 10px 0;
    font-size: 18px;
    font-weight: 700;
    line-height: 45px;
    color: gray;
    font-family: var(--font-comfortaa);
}
.portfolioCard-details{
    font-size: 16px;
    font-weight: 500;
    line-height: 30px;
    text-align: justify;
    font-family: var(--font-comfortaa);
}

.portfolioCard-exitStatusSuccess{
    font-size: 1rem;
    background-color: #BCDDAC;
    padding: 7px 25px;
    border-radius: 500px;
    margin: 15px;
    font-family: var(--font-comfortaa);
    text-align: center;
}

.portfolioCard-exitStatusSeed {
    font-size: 1rem;
    background-color: #EDE4B8;
    padding: 7px 25px;
    border-radius: 500px;
    margin: 15px;
    font-family: var(--font-comfortaa);
    text-align: center;
}
