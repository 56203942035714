@import url('https://fonts.googleapis.com/css2?family=Comfortaa:wght@300;400;500;600;700&display=swap');
@import url('https://fonts.cdnfonts.com/css/neue-haas-grotesk-display-pro');

:root {
  /* Extra small devices (phones, 600px and down) */
  @media only screen and (max-width: 600px) {
    font-size: 10px;
  }

  /* Small devices (portrait tablets and large phones, 600px and up) */
  @media only screen and (min-width: 600px) {
    font-size: 10px;
  }

  /* Medium devices (landscape tablets, 768px and up) */
  @media only screen and (min-width: 768px) {
    font-size: 12px;
  }

  @media only screen and (min-width: 992px) {
    font-size: 14px;
  }

  /* Extra large devices (large laptops and desktops, 1200px and up) */
  @media only screen and (min-width: 1200px) {
    font-size: 14px;
  }



  /* fonts */
  --font-comfortaa: 'Comfortaa', sans-serif;
  --font-soria: 'SoriaFont', sans-serif;
  --font-futura: Futura,Trebuchet MS,Arial,sans-serif;

  /* font sizes */
  --font-size-6xl: 25px;
  --font-size-77xl: 96px;
  --font-size-xs: 12px;
  --font-size-5xl: 24px;
  --font-size-base: 16px;
  --font-size-16xl: 35px;
  --font-size-109xl: 128px;
  --font-size-51xl: 70px;
  --font-size-mini: 15px;

  /* Colors */
  --text-color: #18181b;
  --color-gray-100: #181818;
  --color-gray-200: #111;
  --color-gray-300: #050505;
  --color-floralwhite: #fffcf4;
  --color-black: #000;
  --color-darkslategray: #383e34;

  --bs-link-color-rgb: #18181b;

  /* Gaps */
  --gap-103xl: 122px;
  --gap-71xl: 90px;
  --gap-31xl: 50px;
  --gap-11xl: 30px;

  /* Paddings */
  --padding-3xs: 10px;
  --padding-21xl: 40px;

  /* border radiuses */
  --br-48xl: 67px;
  --br-mini: 15px;
  --br-7xl: 26px;
}

.font-soria {
  font-family: var(--font-soria);
}

.font-futura {
  font-family: var(--font-futura);
}

.font-comfortaa {
  font-family: var(--font-comfortaa);
}

@import '~bootstrap/scss/bootstrap';

:root, [data-bs-theme=light] {
  --bs-link-color-rgb: #18181b;
  --bs-link-hover-color-rgb: #18181b;
}

.text-bg-transparent-light {
  @extend .text-bg-light;

  background-color: transparent !important;
}

.text-bg-transparent-grey {
  @extend .text-bg-transparent-light;

  color: rgba(24, 24, 24, 0.5) !important;
}

img {
  max-width: 100%;
}

.row {
  --bs-gutter-x: 2rem;
}

.btn, button {
  font-family: 'Comfortaa', sans-serif;
  //padding: 0.75rem 2rem;
  --bs-btn-padding-y: 0.75rem;
  --bs-btn-padding-x: 2rem;

  --bs-btn-hover-color: #fff;
}

.btn-primary {
  --bs-btn-color: #fff;
  --bs-btn-bg: #000;

  --bs-btn-border-color: #000;

  --bs-btn-hover-bg: #000;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-border-color: #000;

  --bs-btn-active-bg: #000;
  --bs-btn-active-color: #fff;
  --bs-btn-active-border-color: #000;

  --bs-btn-disabled-bg: #000;
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-border-color: #000;
}

.btn-light {
  --bs-btn-color: #000;
  --bs-btn-bg: #ffffff;
  --bs-btn-border-color: #ffffff;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #d3d4d5;
  --bs-btn-hover-border-color: #c6c7c8;
  --bs-btn-focus-shadow-rgb: 211, 212, 213;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #c6c7c8;
  --bs-btn-active-border-color: #babbbc;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #f8f9fa;
  --bs-btn-disabled-border-color: #f8f9fa;
}

.btn-green {
  --bs-btn-bg: #BCDDAC;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #a2cb90;
}

.btn-yellow {
  --bs-btn-bg: #F3F0B6;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #F3F0B6;
}

@font-face {
  font-family: 'SoriaFont';
  src: url('assets/fonts/soria-font.ttf') format('truetype');
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

body {
  margin: 0;
  line-height: normal;
  width: 100%;
  min-height: 100vh;
}

div#root {
  background-color: #FFFCF4;
}

main {
  margin: 0 calc(var(--bs-gutter-x) * -0.5);
}

form,input {
  font-family: 'Comfortaa', sans-serif;
}

.header {
  max-width: 1440px;
}

.App {
  #text-align: center;
  background: #FFFCF4;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  /*background-color: #282c34;*/
  /*min-height: 100vh;*/
  /*display: flex;*/
  /*flex-direction: column;*/
  /*align-items: center;*/
  /*justify-content: center;*/
  /*font-size: calc(10px + 2vmin);*/
  /*color: white;*/
}

.App-link {
  color: #61dafb;
}

.largePlainText {
  line-height: 5rem;
  font-size: 3.5rem;
  color: var(--color-black);
  font-family: var(--font-soria);
}

.smallPlainText {
  line-height: 35px;
  text-align: justify;
  font-size: 22px;
  color: black;
  font-family: var(--font-comfortaa);
}

.text-justify {
    text-align: justify;
}

.nav-list > * {
    font-size: 0.9rem;
}

.secondary-title {
  font-weight: 400;
  font-family: var(--font-soria);
  font-size: 3rem;
  line-height: 3.5rem;
}

p {
  font-family: var(--font-comfortaa);
  font-size: 1.25rem;
  line-height: 125%;
}
