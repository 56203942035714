.icon-position{
    padding-top: 50px;
}

.who-we-are-banner {
    height: 500px;

    @media screen and (max-width: 768px) {
        height: auto;
    }
}

.who-we-are-banner-text {
    width: 50%;

    @media screen and (max-width: 768px) {
        width: 75%;
    }
}

.who-we-are-container {

    > .container {
        width: 75%;

        @media screen and (max-width: 768px) {
            width: 100%;
        }
    }

    h1 {
        font-size: 3rem;
        font-family: var(--font-soria);
        font-weight: 100;
    }
    p {
        font-size: 1.25rem;
        font-family: var(--font-comfortaa);
        font-weight: 400;
        letter-spacing: 0.5px;
        text-align: justify;
        line-height: 2rem;
    }
}

#who-we-are-accordion {
    --bs-accordion-bg: transparent;
    --bs-accordion-border-color: #000000;
    --bs-accordion-active-bg: transparent;

    .accordion-item{
        &:last-of-type {
            border-bottom: var(--bs-accordion-border-width) solid var(--bs-accordion-border-color);
        }
    }

    .accordion-header {
        font-family: var(--font-soria);

        .accordion-button {
            font-family: inherit;
            font-size: 3rem;
            padding-inline: 0;
            padding-top: 3rem;

            &:focus {
                box-shadow: none;
            }
        }
    }

    .accordion-body {
        padding-inline: 0;

        p {
            font-size: 1.25rem;
        }
    }
}
