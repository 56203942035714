.aboutTeam{
    color: var(--color-black);
    font-family: var(--font-soria);
}

.aboutTeam-mainText {
    font-size: 3rem;
    font-family: SoriaFont, serif;
    width: 80%;

    @media screen and (max-width: 768px) {
        width: 100%;
        padding-inline: 2rem;
    }

    p {

        @media screen and (max-width: 768px) {
            width: 100%;
            margin: 0;
            text-align: justify;
        }
    }
}

.aboutTeam-plainText {
    font-family: var(--font-comfortaa);
    width: 80%;
    font-size: 1.5rem;

    @media screen and (max-width: 768px) {
        width: 100%;
        padding-inline: 2rem;
    }

    p {

        @media screen and (max-width: 768px) {
            width: 100%;
            margin: 0;
            text-align: justify;
        }
    }
}




.aboutTeam-plainText > * {
    position: relative;
    line-height: 168.8%;
    display: flex;
    align-items: center;
    width: 88%;
    margin-left: 100px;
}




