.vision {
    /*display: flex;*/
    /*align-items: center;*/
    /*justify-content: space-between;*/
    /*flex-direction: row;*/
    /*padding: 4rem 200px;*/
    /*height: 600px;*/

    color: var(--color-black);
    font-family: SoriaFont, sans-serif;

    @media screen and (max-width: 768px) {
        height: auto;
        padding: 2rem;
    }

    > .container {
        width: 75%;

        @media screen and (max-width: 768px) {
            width: 100%;

            .vision-text {
                width: 100% !important;
            }
        }
    }
}

.vision-content {
    width: 100%;
}

.vision.horizontal {
    flex-wrap: wrap;
    height: auto;
}

.vision.beige {
    background-color: rgb(236 228 206);
}

.vision-head {
    text-align: center;

    width: 75%;


    @media screen and (max-width: 768px) {
        width: 100%;
    };
}

.vision-text {
    //width: 500px;
    //display: flex;
    //flex-direction: column;
    //justify-content: space-between;
    //align-items: flex-start;
}

.vision-text-head {
    margin-bottom: 3rem;
}

.vision-text p {
    margin-top: 10px;
    margin-bottom: 0;
}

.vision-img {
    /*position: relative;*/
    /*border-radius: var(--br-7xl);*/
    /*background-color: var(--color-gray-200);*/
    /*width: 430px;*/
    /*height: 400px;*/
    /*overflow: hidden;*/
    /*flex-shrink: 0;*/
}

.vision-img>img {
    /*position: absolute;*/
    /*top: 0;*/
    /*left: calc(50% - 312px);*/
    /*width: 616px;*/
    /*height: 400px;*/
    max-width: 100%;
}
