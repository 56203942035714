.our-values {
    line-height: 90.5px;
    text-transform: uppercase;
    font-weight: 800;
    font-family: var(--font-futura);
    color: rgba(29, 28, 27, 0.5);
    text-align: center;
}

.foster-a-culture {
    margin-block-start: 0;
    margin-block-end: 20px;
}

.both-personally-and {
    margin: 0;
}

.foster-a-culture-container {
    font-size: 64px;
    line-height: 90.5px;
    text-transform: uppercase;
    font-family: var(--font-soria);
    color: #1d1c1b;
}

.passion-for-impact {

    line-height: 90.5px;
    text-transform: capitalize;
    font-weight: 500;
}

.add-icon {

    width: 24px;
    height: 24px;
    overflow: hidden;
}

.frame-item {

    border-top: 1px solid #000;
    box-sizing: border-box;
    width: 838px;
    height: 1px;
}

.collaboration-and-teamwork {

    line-height: 90.5px;
    text-transform: capitalize;
    font-weight: 500;
}

.add-icon1 {

    width: 24px;
    height: 24px;
    overflow: hidden;
}

.frame-inner {

    border-top: 1px solid #000;
    box-sizing: border-box;
    width: 838px;
    height: 1px;
}

.learning-and-growth {

    line-height: 90.5px;
    text-transform: capitalize;
    font-weight: 500;
}

.add-icon2 {
    position: absolute;
    top: 982px;
    left: 1779px;
    width: 24px;
    height: 24px;
    overflow: hidden;
}

.line-div {
    position: absolute;
    top: 1065.5px;
    left: 965.5px;
    border-top: 1px solid #000;
    box-sizing: border-box;
    width: 838px;
    height: 1px;
}

.frame-icon {
    position: absolute;
    top: 476px;
    left: 83px;
    border-radius: 25px;
    width: 814px;
    height: 890px;
    overflow: hidden;
    object-fit: cover;
}

.ourValues{

    //display: flex;
    //flex-direction: column;
    //align-items: flex-start;
    //width: 100%;
    //padding: 50px;
    box-sizing: border-box;

    font-size: 1.5rem;
    background-color: #f6f0de;
    color: rgba(29, 28, 27, 0.5);
    font-family: var(--font-comfortaa);


    @media screen and (max-width: 768px) {
        padding: 4rem 2rem;
    }
}
