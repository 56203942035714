.newsPoster {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    color: rgba(24, 24, 24, 0.5);
    font-family: var(--font-futura);
    padding: 200px;
    background-color: #ECE4CE;
    box-sizing: border-box;
}

.pressReleases{
    background-color: #FFFCF4;
    padding: 60px;
}

.pressReleases-title{
    text-transform: uppercase;
    font-size: 28px;
    line-height: 30px;
}

.pressReleases-item {
    border-bottom: 1px solid var(--bs-body-color);
}

.pressReleases-item-left {
    background-color: rgb(24, 24, 24);
    height: 600px;
    border-radius: 10px;
}

.pressReleases-item-left-container {
    margin: 1rem;
}

.pressReleases-item-left img {
    background-color: white;
    width: 100%;
    border-radius: 10px;
}

.pressReleases-item-left h2 {
    font-family: Comfortaa;
    font-size: 1.5rem;
    padding: 1rem;
    color: var(--color-floralwhite);
}

.pressReleases-item-left a {
    color: var(--color-floralwhite);
    font-size: 1rem;
    margin: 0 0 0 1rem;
    text-decoration: none;
}

.pressReleases-item-right {
    height: 647px;
    overflow: auto
}

.pressReleases-item-right img {
    max-width: 100%;
}

.pressReleasesCard{
    display: flex;
    align-items: center;
    height: 350px;
    border-top: 1px solid #ccc;
}
.pressReleasesCard:last-child{
    border-bottom: 1px solid #ccc;
}
.pressReleasesCard >*{
   flex: 1;
   text-align: center;
}

.pressReleasesCard-img{
   height: 200px;
   width: 320px;
   border-radius: 8px;
   /* background-color: black; */
   object-fit: cover;
}

.pressReleasesCard-heading{
    text-transform: capitalize;
    font-size: 1.5rem;
    font-weight: 500;
    text-align: left;
}

.pressReleases-item-right p {
    font-size: 0.8rem;
}

.article-img{
    position: relative;
    border-radius:5px;
    background-color: #2F2D2D;
    width: 75%;
    height: 210px;
    overflow: hidden;
    flex-shrink: 0;
}
