.subscribe {
    //display: flex;
    //flex-direction: column;
    //align-items: center;
    //justify-content: center;
    background-color: #ece4ce;
    font-family: var(--font-soria);
}

.subscribe-text{
    font-family: var(--font-soria);
    text-align: center;
    font-size: 4rem;
    line-height: 5rem;
    margin-bottom: 4rem;
    color: var(--color-gray-100);
    text-transform: capitalize;
}

.inputt {
    width: 60%;
    border: none;
    font-size: 1.5rem;
    border-radius: 500px;
    padding: 10px 0 10px 30px;
    background-color: transparent;
}

.inputt:focus {
    outline: none;
}
.inputt::placeholder {
    font-family: var(--font-comfortaa);
}


.Subs-btn {
    width: 40%;
    border: 1px solid black;
    margin: 6px;
    border-radius: 500px;
    background: transparent;

    color: black;
    font-family: var(--font-comfortaa);;
    font-size: 1.5rem;
}


.newsletter-form-container {
    width: 25%;

    @media screen and (max-width: 768px) {
        width: 75%;
    }
}
