.visionPoints {
  font-size: var(--font-size-109xl);
  color: var(--color-black);
  font-family: var(--font-soria);

  padding: 0 25% 8% 25%;

  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;
}

.visionPoints-item {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 10%;
}

.visionPoints-item-number {
  letter-spacing: 0.05em;
  line-height: 73.5px;
  margin-bottom: 90px;
}

.visionPoints-item-description {
  letter-spacing: 0.05em;
  width: 351px;
}

