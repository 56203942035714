.form-input-pitch{
    border: #F6F0DE;
    border-bottom: thin ridge black;
    width: 300px;
    height: 60px;
    padding: 10px 5px 0px 5px;
    background: #F6F0DE;
}

.form-input-pitch-single{
    border: #F6F0DE;
    border-bottom: thin ridge black;
    width: 300px;
    height: 60px;
    padding: 10px 5px 0px 5px;
    background: #F6F0DE;
    width: 92%;
}

.pitch {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    padding: 30px 200px;
    height: 600px;

    color: var(--color-black);
    font-family: var(--font-soria);
}

.pitch-text {
    width: 800px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
}


.pitch-img {
    position: relative;
    border-radius: var(--br-7xl);
    background-color: var(--color-gray-200);
    width: 520px;
    height: 685px;
    overflow: hidden;
    flex-shrink: 0;
}

#pitch-us-accordion {

    --bs-accordion-bg: transparent;
    //--bs-accordion-border-color: #000000;
    --bs-accordion-active-bg: transparent;

    .accordion-item{
        &:last-of-type {
            border-bottom: var(--bs-accordion-border-width) solid var(--bs-accordion-border-color);
        }
    }

    .accordion-header {

        .accordion-button {
            font-size: 1.5rem;
            padding-inline: 0;
            padding-top: 2rem;

            &:focus {
                box-shadow: none;
            }
        }
    }

    .accordion-body {
        padding-inline: 0;

        p {
            //font-size: 1.25rem;
        }
    }
}

.pitch-forms {
    width: 50%;

    .form-control {
        &[type="file"] {
            background-color: transparent;
        }
    }

    .form-floating > .form-control {
        background-color: transparent;
        border-radius: 0;
        border-width: 0 0 1px;
        padding-top: 2rem;
        padding-inline: 0;

        ~ label {
            padding-inline: 0;
        }

        &:focus,
        &:not(:placeholder-shown) {
            box-shadow: none;
            border-radius: 0;
        }

        &:focus ~ label,
        &:not(:placeholder-shown) ~ label {
            padding-inline: 0;
            transform: scale(0.85) translateY(-0.5rem) translateX(0);
        }

        &:focus ~ label::after,
        &:not(:placeholder-shown) ~ label::after {
            background-color: transparent;
        }
    }

    @media screen and (max-width: 768px) {
        width: 100%;
    }

    .nav-tabs {
        --bs-nav-link-color: rgba(0, 0, 0, 0.85);
        --bs-nav-link-hover-color: #000;
        --bs-nav-tabs-link-active-bg: transparent;
        --bs-nav-tabs-link-active-border-color: #000000;

        .nav-link {
            border-width: 0 0 1px 0;

            &.active {
                font-weight: 600;
            }
        }
    }
}

.pitch-bottom {
    > div {
        > .align-self-center {

            width: 75%;

            @media screen and (max-width: 768px) {
                width: 100%;
            }
        }
    }
}

