.contact{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 auto;
}

.contact-cards {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 85%;
    font-size: var(--font-size-16xl);
    color: var(--color-floralwhite);
    font-family: var(--font-soria);
    gap: 15px;
    flex-wrap: wrap;
}

.contact-card {
    padding: 30px;
    width: 350px;
    height: 300px;

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    box-sizing: border-box;

    border-radius: var(--br-mini);
    background-color: var(--color-darkslategray);
}

.contact-title {
    flex: 1 1;
    line-height: 40px;
    font-size: 32px;
    text-transform: capitalize;
    display: flex;
    align-items: flex-end;
    width: 100%;

    a {
        font-size: 1.25rem;

        @media screen and (max-width: 768px) {
            font-size: 1.5rem;
        }
    }
}

.contact-description {
    flex: 1;
    width: 294px;
    line-height: 25px;
    font-size: 14px;
    font-family: var(--font-comfortaa);

    p {
        font-size: 1.25rem;

        @media screen and (max-width: 768px) {
            font-size: 1.5rem;
        }
    }
}
.contact-us {
    position: relative;
    letter-spacing: 0.05em;
    line-height: 38px;
}

.contact-us-wrapper {
    display: flex;
    flex-direction: row;
    padding: var(--padding-3xs);
    align-items: flex-start;
    justify-content: flex-start;
}

.contact-btn {
    margin: 10px 0;
    border-radius: var(--br-7xl);
    border: 1px solid var(--color-gray-300);
    padding: 0 2rem;
    font-size: var(--font-size-base);
    color: var(--color-gray-300);
    font-family: var(--font-comfortaa);
    transition: color 1s ease, background-color 1s ease;
    background-color: var(--color-floralwhite);
    line-height: calc(2 * var(--br-7xl));
    text-decoration: none;
}
.contact-btn:hover {
    color: white;
    background-color: var(--color-gray-300);
    font-family: var(--font-comfortaa);
    transition: color 1s ease, background-color 1s ease; /* Adding transition */
}

.score-card {
    padding: 4rem 2rem;
    min-width: 18rem;
    text-align: center;
}

.score-card h2 {
    font-size: 2rem;
    font-family: 'Open Sans', sans-serif;
    color: #231F1F80;
}
