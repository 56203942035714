footer {


    .navbar-nav {
        gap: 1px;
        background: rgb(0 0 0 / 65%);

        --bs-nav-link-padding-x: 15px;
        --bs-nav-link-padding-y: 0;

        --bs-navbar-nav-link-padding-x: 15px;
        --bs-navbar-nav-link-padding-y: 0;
    }

    .nav-item {
        background-color: #FFFCF4;
        line-height: normal;
    }

    .nav-link {
        --bs-nav-link-color: rgb(0 0 0 / 65%);

        &:hover {
            --bs-nav-link-hover-color: rgb(0 0 0 / 80%);
        }
    }
}

.footer {
    font-size: var(--font-size-xs);
    color: var(--color-black);
    font-family: var(--font-comfortaa);
    line-height: 2rem;
    letter-spacing: 0.05em;

    @media screen and (max-width: 768px) {
        text-align: center;
    }

     > div {
        @media screen and (max-width: 768px) {
            flex-direction: column-reverse !important;
        }
    }

    nav {
        @media screen and (max-width: 768px) {
            justify-content: center !important;
        }
    }
}

.footer-right {
    display: flex;
    gap: 1px;
    background-color: var(--color-black);
    align-items: center;
    line-height: normal;
}

.footer-right>* {
    background-color: #fff;
    padding: 0 15px;
    text-transform: uppercase;
}
