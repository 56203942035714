header {
    .navbar-nav {
        --bs-nav-link-padding-x: 0.75rem;
        --bs-navbar-nav-link-padding-x: 0.75rem;
    }

    @media screen and (max-width: 768px) {
        @extend .border-bottom !optional;
        @extend .border-secondary !optional;;
    }
}

.navbar {
    font-family: var(--font-comfortaa);
    text-transform: uppercase;
}

.logo {
    position: relative;
    width: 58px;
    height: 48px;
}

#navbarMain {
    @media screen and (max-width: 768px) {
        margin-top: 2rem;
        text-align: right;
    }
}
