.info-container {
    display: flex;
    justify-content: space-between;
  }
  

  .info-left,
  .info-right {
    flex: 1;
    padding:20px;
}
h2.bottom-border{
    border-bottom: ridge;
    padding: 10px;
    line-height: 38px;
    font-size: 26px;
    font-weight: 700;
    font-family: 'SoriaFont'
}