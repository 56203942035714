.about-us1 {
    font-weight: 800;
    font-size: 1.5rem;
    font-family: var(--font-futura);
    padding: 1rem 0;
}

.about-us2 {
    font-size: 4rem;
    font-family: 'SoriaFont', serif;
    color: var(--color-black);
    margin-bottom: 10px;
    max-width: 75%;
}


.aboutUsPoster-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.aboutUsPoster {
    display: flex;
    flex-direction: column;
    background-color: #ece4ce;
    height: 500px;
    justify-content: center;
    font-size: var(--font-size-13xl);
    color: rgba(24, 24, 24, 0.5);
    font-family: var(--font-futura);

    @media screen and (max-width: 768px) {
        height: auto;
    }
}





.impact-business-investment {
    position: relative;
    line-height: 150%;
    display: flex;
    align-items: center;
}

.about-us-child,
.impact-business-investment-gro-parent {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
}

.impact-business-investment-gro-parent {
    gap: var(--gap-11xl);
}

.about-us-child {
    position: absolute;
    top: 1002px;
    left: 282px;
    text-align: justify;
    font-size: var(--font-size-5xl);
    font-family: var(--font-comfortaa);
}

.from-ideas-to {
    position: relative;
    line-height: 73.5px;
    text-transform: capitalize;
}



.aboutUsBody {
    //margin: 150px;
    font-size: 1.2rem;
    text-align: justify;

    > .container {
        padding: 8rem;
        line-height: 2rem;


        @media screen and (max-width: 768px) {
            padding: 4rem 2rem;
        }
    }


    @media screen and (max-width: 768px) {
        //margin: 2rem auto;
    }
}

.aboutUsBody-child {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    margin-bottom: 40px;
}

.aboutUsBody-child h1{
    font-size: 3rem;
    line-height: 70px;
    font-weight: 500;
    text-transform: capitalize;
    font-family: var(--font-soria);
}

.aboutUsBody-child div li {
    margin-bottom: 20px;
    line-height: 150%;
    font-size: var(--font-size-5xl);
    font-family: var(--font-comfortaa);
}
